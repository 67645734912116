import React, { Component } from 'react'
import Navigation from './components/navigation';
import Header from './components/header';
import Features from './components/features';
import About from './components/about';
import Services from './components/services';
import Gallery from './components/gallery';
import Testimonials from './components/testimonials';
import Team from './components/Team';
import Contact from './components/contact';
import JsonData from './data/data.json';
import { Route, Router, Switch } from 'react-router-dom';
import { history } from './helpers/history';
import ScrollIntoView from './utils/ScrollIntoView';
//import './style.scss';

export class App extends Component {
  state = {
    landingPageData: {},
  }
  getlandingPageData() {
    this.setState({landingPageData : JsonData})
  }
  componentDidMount() {
    this.getlandingPageData();
  }

  render() {
    return (
      <Router history={history}>
          <Navigation />
          <Switch>
            <Route exact path="/">
              <div>
                <Header data={this.state.landingPageData.Header} />
                <Features data={this.state.landingPageData.Features} />
                <About data={this.state.landingPageData.About} />
                <Services data={this.state.landingPageData.Services} />
                <Gallery />
                <Testimonials data={this.state.landingPageData.Testimonials} />
                <Team data={this.state.landingPageData.Team} />
              </div>
            </Route>
            <Route exact path="/services/website">
            <div>WEB SITE</div><div>WEB SITE</div><div>WEB SITE</div><div>WEB SITE</div><div>WEB SITE</div><div>WEB SITE</div><div>WEB SITE</div><div>WEB SITE</div><div>WEB SITE</div><div>WEB SITE</div>
            </Route>
            <Route path="/services/webapp_and_mobile_app">
            <div>webapp_and_mobileapp</div> <div>webapp_and_mobileapp</div> <div>webapp_and_mobileapp</div> <div>webapp_and_mobileapp</div> <div>webapp_and_mobileapp</div> <div>webapp_and_mobileapp</div> <div>webapp_and_mobileapp</div>
            </Route>
            <Route path="/services/software">
              <div>software</div>
            </Route>
            <Route path="/services/graphic_design">
              <div>graphic_design</div>
            </Route>
            <Route path="/services/digital_marketing">
              <div>digital_marketing</div>
            </Route>
            <Route path="/services/consulting">
              <div>consulting</div>
            </Route>
          </Switch>
          <Contact data={this.state.landingPageData.Contact} />
      </Router>
    )
  }
}

export default App;
